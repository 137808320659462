<template>
  <div v-if="isLoading" class="d-flex flex-column justify-content-center">
    <hs-loading />
  </div>
  <section class="content-editor" v-else>
    <hsPageHeader
      :title="lesson.title"
      :back-text="
        course.kind === 'online_course'
          ? $t('breadcrumb.ContentsList')
          : $t('sparkmembers.coupons.views.list.components.page-header.back-button-label')
      "
      :back-route="course.kind === 'online_course' ? 'Contents' : 'ProductDashboard'"
    >
      <template slot="actions">
        <a class="text-decoration-none text-dark" target="_blank" :href="showLesson">
          <hs-icon class="mr-1" variant="light" icon="eye" />
          {{ $t(`sparkmembers.contents.views.editor.components.${type}.components.page-header.show-${type}`) }}
        </a>
      </template>
    </hsPageHeader>
    <section class="container-fluid">
      <div class="row mt-5 px-4">
        <div class="col-xl-9">
          <MainContent
            id="lesson-main-content"
            class="main-content-card"
            :activity="activity"
            :coverImage="coverImage"
            :media="media"
            :isThumbnailLoading="isThumbnailLoading"
            @selected-thumbnail="selectedThumbnail"
            @remove-thumbnail="removeThumbnail(media)"
            @media="onMainContentMedia"
            @activity="onMainContentActivity"
            @premium="onPremiumFeature"
            :lessonKind="course.kind"
          />

          <section class="bg-white p-4 mb-5 rounded-sm" data-track="lesson details">
            <h5 class="font-weight-bold mb-2">
              {{ $t(`sparkmembers.contents.views.editor.components.${type}.index.${type}-details.title`) }}
            </h5>

            <p class="font-size-sm p-0 mb-3">
              {{ $t(`sparkmembers.contents.views.editor.components.${type}.index.${type}-details.subtitle`) }}
            </p>

            <hs-group class="mb-4">
              <label for="edit-lesson-name" class="font-weight-bold">
                {{ $t(`sparkmembers.contents.views.editor.components.${type}.index.${type}-details.name-group.label`) }}
              </label>

              <MInput
                :placeholder="
                  $t(
                    `sparkmembers.contents.views.editor.components.${type}.index.${type}-details.name-group.input-placeholder`
                  )
                "
                :state="!$v.lesson.title.$error ? null : false"
                v-model="lesson.title"
                @blur="$v.lesson.title.$touch()"
              />

              <hs-form-invalid-feedback :state="!$v.lesson.title.$error">
                <span class="font-size-xs" v-if="!$v.lesson.title.required">
                  {{ $t('validations.required') }}
                </span>
              </hs-form-invalid-feedback>
            </hs-group>

            <hs-group
              class="font-weight-bold"
              :label="
                $t(
                  `sparkmembers.contents.views.editor.components.${type}.index.${type}-details.description-group.label`
                )
              "
            >
              <Editor
                v-model="lesson.description"
                :initial-value="lesson.description || ''"
                :init="editorDefaultInit"
              />
            </hs-group>

            <div class="d-flex justify-content-end mt-4">
              <MXButton
                id="on-details-saved"
                variant="primary"
                @click="course.kind === 'online_course' ? onDetailsSaved() : onEditLesson()"
                :disabled="isUpdating"
              >
                {{ $t(`sparkmembers.contents.views.editor.components.${type}.index.${type}-details.save`) }}
              </MXButton>
            </div>
          </section>

          <AdditionalMedias
            v-if="!activity"
            type="lesson"
            :additionalMedias="additionalMedias"
            @add="additionalMediaAdded"
            @change="additionalMediaChanged"
            data-track="lesson extra content"
            @remove="additionalMediaRemoved"
            @move="additionalMediaMoved"
            @premium="onPremiumFeature"
          />

          <Automations
            id="automations"
            type="lesson"
            :automations="automations"
            data-track="lesson automation"
            @save="automationSaved"
            @change="automationChanged"
            @remove="automationRemoved"
            @premium="onPremiumFeature"
          />

          <section class="d-flex flex-column flex-md-row mb-5" v-if="course.kind === 'online_course'">
            <div class="align-self-center align-self-md-start flex-md-grow-1">
              <MXButton
                id="delete-lesson-modal"
                icon="far fa-times-circle"
                variant="danger-secondary"
                @click="onDeleteLesson()"
                v-b-modal="'delete-lesson-modal'"
              >
                {{ $t(`sparkmembers.contents.views.editor.components.${type}.index.buttons.delete-${type}`) }}
              </MXButton>
            </div>

            <MXButton
              id="edit-lesson-and-back"
              variant="secondary"
              @click="onEditLessonAndBack()"
              :disabled="isUpdating"
            >
              {{
                isUpdating
                  ? $t(`sparkmembers.contents.views.editor.components.${type}.index.buttons.saving`)
                  : $t(`sparkmembers.contents.views.editor.components.${type}.index.buttons.save-and-return`)
              }}
            </MXButton>

            <MXButton variant="primary" @click="onEditLessonAndNext()" :disabled="!nextContentId" class="ml-4">
              {{
                isUpdating
                  ? $t(`sparkmembers.contents.views.editor.components.${type}.index.buttons.saving`)
                  : $t(`sparkmembers.contents.views.editor.components.${type}.index.buttons.save-and-edit-next-${type}`)
              }}
            </MXButton>
          </section>
        </div>
        <div class="col-xl-3">
          <div class="d-flex flex-column">
            <VisibilityCard v-model="content.available" @input="onEditLesson()" />
            <CommentsCard v-if="!activity" v-model="commentsActivated" :type="type" @input="onEditLesson()" />
            <LessonReleaseLimitationCard
              v-model="lesson.schedule"
              :id="this.selectedSchool.id"
              :email="this.selectedSchool.email"
              @input="onEditLesson()"
            />
          </div>
        </div>
      </div>
    </section>

    <hsConfirmModal
      id="delete-lesson-modal"
      icon="trash-alt"
      variant="cherry"
      hide-header
      :customTitle="
        $t('sparkmembers.contents.views.editor.components.lesson.index.confirm-box.delete-lesson.content-title')
      "
      :description="
        $t('sparkmembers.contents.views.editor.components.lesson.index.confirm-box.delete-lesson.content-description')
      "
      :ok-title="$t('sparkmembers.contents.views.editor.components.lesson.index.confirm-box.delete-lesson.ok-title')"
      :cancel-title="
        $t('sparkmembers.contents.views.editor.components.lesson.index.confirm-box.delete-lesson.cancel-title')
      "
      @ok="onDeleteLesson"
    />

    <hsPremiumModal id="premium-modal" @click="requestPremiumFeature" />
  </section>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapActions, mapState, mapGetters } from 'vuex';
import { hsLoading, hsConfirmModal, hsPremiumModal } from '@/components';
import ThumbnailMixin from '@/mixins/ThumbnailMixin';
import CommentsCard from '../CommentsCard';
import hsPageHeader from '@/components/_structures/PageHeader';
import ToastHelper from '@/shared/helpers/toast';
import VisibilityCard from './components/VisibilityCard';
import MainContent from './components/MainContent';
import LessonReleaseLimitationCard from './components/LessonReleaseLimitationCard';
import AdditionalMedias from '../AdditionalMedias';
import Automations from '../Automations.vue';
import Editor from '@tinymce/tinymce-vue';
import { editorDefaultInit } from '@/libs/tinymce';
import AutomationMixin from '@/sparkmembers/views/Contents/mixins/AutomationMixin';
import { ActivityService } from '@/sparkmembers/services/activity';
import lessonService from '@/sparkmembers/services/lesson';
import courseService from '@/sparkmembers/services/course';
import mediaService from '@/sparkmembers/services/media';
import analyticsService from '@/services/analytics';
import uploadService from '@/services/upload';
import debug from 'debug';
import MXButton from '@/shared/components/MXButton.vue';
import MInput from '@/components/MInput.vue';
import { getAdminViewSsoUrl } from '@/shared/helpers/general';

const logging = debug('hs:lesson-media');

export default {
  name: 'LessonEditor',
  mixins: [AutomationMixin, ThumbnailMixin],
  validations: {
    lesson: {
      title: {
        required,
      },
    },
  },

  components: {
    hsLoading,
    hsConfirmModal,
    CommentsCard,
    LessonReleaseLimitationCard,
    hsPageHeader,
    VisibilityCard,
    MainContent,
    Automations,
    AdditionalMedias,
    hsPremiumModal,
    Editor,
    MXButton,
    MInput,
  },

  data() {
    return {
      type: 'lesson',
      content: {},
      lesson: { schedule: {} },
      activity: null,
      media: null,
      medias: [],
      coverImage: null,
      commentsActivated: null,
      isLoading: true,
      isUpdating: false,
      isAddingAdditionalMedia: false,
      nextContentId: null,
      premiumFeatureLocation: null,
      editorDefaultInit: { ...editorDefaultInit },
      redirectTo: {},
    };
  },

  watch: {
    'activity.max_attempts': {
      handler(value) {
        if (value !== '' && value <= 0) {
          this.$nextTick(() => {
            this.activity.max_attempts = 1;
          });
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters('school', ['getDomain']),
    ...mapState({
      product: state => state.product.selectedProduct,
      loggedUser: state => state.auth.loggedUser,
      course: state => state.course.selectedCourse,
      selectedSchool: state => state.school.selectedSchool,
    }),
    additionalMedias() {
      return this.media ? this.medias.filter(m => m.id !== this.media.id) : this.medias;
    },
    showLesson() {
      return getAdminViewSsoUrl({
        domain: this.getDomain,
        user: this.loggedUser,
        redirectTo: `/admin_view/courses/${this.course.id}/course_contents/${this.$route.params.contentId}`,
      });
    },
  },

  async mounted() {
    this.loadContent();
  },

  methods: {
    ...mapActions('school', ['updateSchool']),
    async onEditLesson() {
      try {
        if (!this.checkAllConditions()) return;

        this.isLoading = true;

        if (!this.$v.$invalid) {
          this.isUpdating = true;
          let lesson = { ...this.lesson, ...this.lesson.schedule };

          if (this.coverImage) {
            lesson.cover_image_id = this.coverImage.id;
          }

          if (this.activity && this.activity.exam_lesson) lesson = { ...lesson, ...this.activity.exam_lesson };
          if (this.commentsActivated) {
            // eslint-disable-next-line no-unused-vars
            var { forum, section } = await lessonService.addCommentsInLesson({
              title: this.lesson.title,
              description: this.lesson.description,
              school_product_id: this.product.id,
              lesson_id: lesson.id,
            });
            lesson.section_id = section.id;
            this.lesson.section_id = section.id;
          } else {
            lesson.section_id = null;
            this.lesson.section_id = null;
          }

          await courseService.updateCourseContent(this.content);
          await lessonService.update(lesson);
          await this.editQuiz();

          this.$router.push(this.redirectTo);

          ToastHelper.successMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.edited-lesson.success')
          );
        } else {
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.edited-lesson.error')
          );
        }
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.edited-lesson.error')
        );
      } finally {
        this.isUpdating = false;
        this.isLoading = false;
      }
    },

    checkAllConditions() {
      return (
        this.questionsMatchMinimumGrade() &&
        this.questionsHasCorrectAnswer() &&
        this.everyAnswerHasATitle() &&
        this.everyQuestionHasTitle() &&
        this.hasEqualAnswerTitle()
      );
    },

    async editQuiz() {
      if (!this.activity || !this.activity.exam_questions) {
        return;
      }
      this.activity.exam_questions.forEach(element => {
        if (!element.isEnableComment) element.comment = null;
        delete element.isEnableComment;
      });
      this.activity.exam_questions.forEach((item, index) => {
        if (item.correct || item.correct === 0) {
          this.activity.exam_questions[index].multiple_choice_options[item.correct].correct = true;
        }
        let multiple_choice_options = item.multiple_choice_options && this.arrayToHash(item.multiple_choice_options);
        this.activity.exam_questions[index].options_attributes = multiple_choice_options;
        this.activity.exam_questions[index].type = item.type;
      });
      const params = { ...this.activity, exam_questions_attributes: this.arrayToHash(this.activity.exam_questions) };
      delete params.exam_questions;
      await ActivityService.update(params);
    },

    arrayToHash(arr) {
      let obj = {};
      arr.forEach((item, index) => (obj[index] = item));
      return obj;
    },

    async onEditLessonAndBack() {
      if (this.checkAllConditions()) {
        this.redirectTo = { name: 'Contents' };
        await this.onEditLesson();
      }
    },

    async onEditLessonAndNext() {
      if (this.checkAllConditions()) {
        this.redirectTo = {
          name: 'ContentEditor',
          params: { contentId: this.nextContentId },
          query: { type: 'Lesson' },
        };
        this.media = null;
        this.medias = [];

        await this.onEditLesson();
        await this.loadContent();
        window.scrollTo(0, 0);
      }
    },

    questionsMatchMinimumGrade() {
      if (this.activity && this.activity.exam_questions.length > 0) {
        const total = this.activity.exam_questions
          .map(item => parseInt(item.point))
          .reduce((accumulator, currentValue) => accumulator + currentValue);

        if (isNaN(total) || total < this.activity.exam_lesson.min_grade) {
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.toast.activity_creation.minimun-grade')
          );
          return false;
        }
      }
      return true;
    },

    questionsHasCorrectAnswer() {
      if (this.activity && this.activity.exam_questions.length) {
        const hasCorrect = this.activity.exam_questions.every(item => {
          if (item.type == 'MultipleChoiceExamQuestion') {
            return item.correct >= 0;
          }
          return true;
        });

        if (!hasCorrect) {
          ToastHelper.dangerMessage(
            this.$t(
              'sparkmembers.contents.views.editor.components.lesson.toast.activity_creation.select-correct-answer'
            )
          );
          return false;
        }
      }
      return true;
    },

    everyAnswerHasATitle() {
      if (this.activity && this.activity.exam_questions.length) {
        const checkTitles = this.activity.exam_questions
          .map(item =>
            item.multiple_choice_options.every(item => {
              if (item.type == 'MultipleChoiceExamQuestion') {
                return item.title;
              }
              return true;
            })
          )
          .every(item => item);
        if (!checkTitles) {
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.toast.activity_creation.no-answer-title')
          );
          return false;
        }
      }
      return true;
    },

    everyQuestionHasTitle() {
      if (this.activity && this.activity.exam_questions.length) {
        const checkTitles = this.activity.exam_questions.every(item => item.title);
        if (!checkTitles) {
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.toast.activity_creation.no-question-title')
          );
          return false;
        }
      }
      return true;
    },

    hasEqualAnswerTitle() {
      if (this.activity && this.activity.exam_questions.length) {
        const isEqual = this.activity.exam_questions
          .map(item => {
            if (item.type == 'MultipleChoiceExamQuestion') {
              const values = item.multiple_choice_options.map(object => object.title);
              return values.some((object, index) => values.indexOf(object) !== index);
            }
            return false;
          })
          .some(item => item === true);
        if (isEqual) {
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.toast.activity_creation.equal-answer-title')
          );
          return false;
        }
      }
      return true;
    },

    async onDeleteLesson() {
      try {
        this.isLoading = true;
        await courseService.deleteCourseContent(this.content.id, this.content);
        await lessonService.delete(this.lesson);

        const { course_contents } = await courseService.getCourseContents(this.content.course_module_id);
        const contentIds = course_contents.map(item => item.id);
        await courseService.normalizeCourseContentsOrder(contentIds);
        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.deleted-lesson.success')
        );
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.deleted-lesson.error')
        );
      } finally {
        this.$router.push({ name: 'Contents' });
      }
    },

    async loadContent() {
      try {
        this.isLoading = true;
        const { data: content } = await courseService.getCourseContent(this.$route.params.contentId);

        const { course_contents } = await courseService.getCourseContents(content.course_module_id);
        const contentIndex = course_contents.findIndex(c => c.id === content.id);
        if (course_contents[contentIndex + 1]) this.nextContentId = course_contents[contentIndex + 1].id;
        else this.nextContentId = null;
        content.available = !!content.available;

        this.content = content;

        const {
          id,
          title,
          description,
          available,
          section_id,
          release_at,
          release_after,
          lock_at,
          lock_after,
          cover_image,
          media,
          contents,
          activity,
        } = await content.lesson;

        this.coverImage = cover_image;

        this.commentsActivated = section_id !== null;

        if (media) this.media = (await mediaService.get(media.id)).data;

        if (contents) {
          const content_ids = contents.map(c => c.content.id);
          const medias = (await mediaService.getAll(content_ids)).map(r => r.data);
          this.medias = content_ids.map(cid => medias.find(m => m.id === cid));
        }

        if (activity) {
          this.activity = await ActivityService.get(activity.id);
        }

        this.lesson = {
          id,
          title,
          description,
          available,
          section_id,
          activity,
          schedule: {
            release_at,
            release_after,
            lock_at,
            lock_after,
          },
        };
        await this.loadAutomationsFromSelectedLesson();
      } catch (error) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.loaded-lesson.error')
        );
      } finally {
        this.isLoading = false;
      }
    },

    async onMainContentActivity(attrs) {
      this.isLoading = true;

      if (!attrs) {
        try {
          await lessonService.update({
            id: this.lesson.id,
            type: 'ExamLesson',
            min_grade: 0,
          });
          this.lesson.type = 'ExamLesson';

          this.activity = await ActivityService.create({
            exam_lesson_id: this.lesson.id,
            exam_questions_attributes: {},
            max_attempts: 1,
            retry_time: 0,
            time_limit: 0,
            title: this.$t('sparkmembers.contents.views.editor.components.lesson.title'),
            titleHasError: false,
            type: 'Quiz',
          });

          if (this.medias.length > 0) {
            await lessonService.update({
              id: this.lesson.id,
              media_id: null,
              content_ids: [],
            });
            this.media = null;
            this.medias = [];
          }

          ToastHelper.successMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.toast.activity_creation.success')
          );
        } catch (e) {
          ToastHelper.dangerMessage(
            this.$t('sparkmembers.contents.views.editor.components.lesson.toast.activity_creation.error')
          );
        }
      }

      this.isLoading = false;
    },

    async onMainContentMedia(media) {
      try {
        this.isLoading = true;

        const content_ids = this.medias.map(m => m.id);
        const newMedia = (await mediaService.create({ media })).data;

        if (this.media) content_ids[0] = newMedia.id;
        else content_ids.unshift(newMedia.id);

        const ContentLesson = 'ContentLesson';

        const lessonData = {
          id: this.lesson.id,
          type: ContentLesson,
          media_id: newMedia.id,
          content_ids,
        };

        if (this.coverImage) {
          lessonData.cover_image_id = this.coverImage.id;
        }

        await lessonService.update(lessonData);
        this.lesson.type = ContentLesson;

        if (this.media) this.medias[0] = newMedia;
        else this.medias.unshift(newMedia);

        this.media = newMedia;

        this.activity && (await ActivityService.delete(this.activity.id));
        this.activity = null;

        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.main-content-save.success')
        );

        analyticsService.track({
          event: 'Lesson content added',
          props: {
            type: this.normalizeType(this.media.type, this.media.host),
            school_id: this.selectedSchool.id,
          },
        });
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.main-content-save.error')
        );
      } finally {
        this.isLoading = false;
      }
    },

    async onDetailsSaved() {
      try {
        if (!this.questionsMatchMinimumGrade()) return;

        this.isLoading = true;

        await lessonService.update({
          id: this.lesson.id,
          title: this.lesson.title,
          description: this.lesson.description,
        });

        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.details-save.success')
        );
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.details-save.error')
        );
      } finally {
        this.isLoading = false;
      }
    },

    async additionalMediaAdded(attrs) {
      this.isLoading = true;

      try {
        const newAdditionalMedia = (await mediaService.create({ media: { ...attrs } })).data;
        const content_ids = this.medias.map(m => m.id);

        content_ids.push(newAdditionalMedia.id);

        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-add.success')
        );

        this.medias.push(newAdditionalMedia);

        this.isLoading = false;
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-add.error')
        );

        this.isLoading = false;
      }
    },

    async additionalMediaChanged(attrs) {
      try {
        await mediaService.update(attrs);

        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-update.success')
        );
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-update.error')
        );
      }
    },

    async additionalMediaRemoved(id) {
      this.isLoading = true;

      try {
        let content_ids = this.medias.map(m => m.id);
        content_ids = content_ids.filter(cid => cid !== id);

        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-remove.success')
        );

        this.medias = this.medias.filter(m => m.id !== id);
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-remove.error')
        );
      }

      this.isLoading = false;
    },

    async additionalMediaMoved(additionalMedias) {
      const content_ids = additionalMedias.map(am => am.id);
      if (this.media) content_ids.unshift(this.media.id);

      try {
        await lessonService.update({
          id: this.lesson.id,
          content_ids,
        });

        this.medias = [...additionalMedias];
        if (this.media) this.medias.unshift(this.media);

        ToastHelper.successMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-move.success')
        );
      } catch (e) {
        ToastHelper.dangerMessage(
          this.$t('sparkmembers.contents.views.editor.components.lesson.index.toast.additional-media-move.error')
        );
      }
    },

    async onPremiumFeature(feature) {
      this.premiumFeatureLocation = `lesson ${feature.toLowerCase()}`;
      this.$bvModal.show('premium-modal');
      analyticsService.track({
        event: 'Paywall displayed',
        props: {
          location: this.premiumFeatureLocation,
        },
      });
      const school = this.selectedSchool;
      school.extra_settings.show_top_bar = 'true';
      await this.updateSchool({ id: school.id, extra_settings: school.extra_settings });
    },

    requestPremiumFeature() {
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal');
    },

    normalizeType(type, host = null) {
      return host ? host : type.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/variables/_colors.scss';
.main-content-card {
  min-height: 217px;
}
.content-editor {
  .bg-yellow {
    color: $yellow-dark;
    background: $yellow-light-02;
  }
}
</style>
